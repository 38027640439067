import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../../static/styles/index.module.scss"

const hero = () => {
    return (
        <div className={style.heroImgTextContainer}>
            <StaticImage 
                src="../../static/images/gamersmancave.png" 
                alt="hero" 
                className={style.heroImg}
                quality={90} 
                placeholder="blurred" 
                formats={["AUTO", "WEBP", "AVIF"]}
            />
            <p>Gamersmancave Benelux voor al je gaming nieuws!</p>
        </div>
    )
}

export default hero