import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import * as style from "../../static/styles/pagination.module.scss"

const Pagination = ({ pageContext }) => {
    const { numberPages, currentPage } = pageContext

    const pageNumbers = []
    Array.from({ length: numberPages }, (_, i) => {   // Array.from is to make an array-ish thing
        return pageNumbers.push(i)
    })

    const [startNum, setStartNum] = useState(0)
    const [endNum, setEndNum] = useState(4)
    useEffect(() => {
        if(0 < currentPage -3){         // Normal situation
            setStartNum(currentPage -3)
            setEndNum(currentPage + 2)
        }else if(currentPage -3 < 1) {  // If startNum will be minus
            setStartNum(0)
            setEndNum((currentPage + 2)) // If page is first page = index.js
        }else if(currentPage === 1){ 
            setStartNum(0)
            setEndNum(4)
        }
    },  [currentPage, numberPages])
    
    return (
        <p className={style.paginationWrapper}>
            {5 <= currentPage && <Link to={`/`}>1<span> ···</span></Link>}
            {4 === currentPage && <Link to={`/`}>1</Link>}
            {pageNumbers.slice(startNum, endNum).map((x, index) => (
                <Link key={index} to={`/${x === 0 ? "" : x + 1}`} style={{                                          
                        color: x + 1 === currentPage ? '#fff' : '#00aeef',
                        background: x + 1 === currentPage ? '#00aeef' : '',}}
                >            
                {x + 1}
                </Link>
            ))}
            {currentPage < numberPages -3 && <Link to={`/${numberPages}`}><span>··· </span>{numberPages}</Link>}
            {currentPage === numberPages -3 && <Link to={`/${numberPages}`}>{numberPages}</Link>}
        </p>
    )
}

export default Pagination