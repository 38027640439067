import React from 'react'
import { graphql/* , Link */ } from 'gatsby'
import Seo from "../components/seo"
import Hero from "../components/indexComponents/hero"
//import Ad from "../components/indexComponents/ad"
import Content from "../components/indexComponents/content"
import Pagination from "../components/indexComponents/pagination"
import Footer from "../components/footer"

const Index = ({ data, pageContext }) => {
  return (
    <div>
      <Seo title="Gamersmancave"/>
      <Hero/>
      <Content posts={data.allContentfulBlogPost.edges}/>
      <Pagination pageContext={pageContext}/>
      <Footer/>
    </div>
  )
}

export default Index

export const pageQuery = graphql`
query IndexQuery ($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
        sort: { fields: [publishDate], order: DESC }
        limit: $limit
        skip: $skip
      ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
              gatsbyImageData(
                  placeholder: BLURRED,
                  width: 350,
                  height: 196,
                  resizingBehavior: SCALE,
              )
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
}
`